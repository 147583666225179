import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../services/dialog.service';
import { MediaService } from 'src/app/services/media.service';
import { LoadingService } from 'src/app/services/loading.service';

import { Media } from '../../../structures/media';

/**
 * Composant gérant l'affichage des clouds dans le menu de gauche
 */

@Component({
    selector: 'app-nav-entry',
    templateUrl: './nav-entry.component.html',
    styleUrls: ['./nav-entry.component.scss']
})
export class NavEntryComponent implements OnInit, OnDestroy {
    /**
     * @param {any} media le media qui est dans cette entree.
     * @param {number} selectedMedia l'id du media selectionne.
     * @param {string} type sert a savoir si l'utilisateur est autheur ou non du media.
     */
    @Input() media: Media;
    @Input() type: string;
    @Input() selectedMedia: number;

    cloneMedia$: Subscription;
    renamedMedia$: Subscription;

    constructor(
        private dialogService: DialogService,
        private mediaService: MediaService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.renamedMedia$ = this.mediaService.renamedMedia.subscribe((media: Media) => {
            if (this.media.id === media.id) {
                this.media.name = media.name;
            }
        });
    }

    ngOnDestroy() {
        if (this.renamedMedia$) {
            this.renamedMedia$.unsubscribe();
        }
        if (this.cloneMedia$) {
            this.cloneMedia$.unsubscribe();
        }
    }

    getBackgroundStyle() {
        return `background-${this.media.status}`;
    }

    /**
     * retourne la classe selected si le média est le selectedMedia
     * @returns {string} selected || string vide
     */
    isSelected(): String {
        return this.media.id === this.selectedMedia ? 'selected' : '';
    }

    /**
     * @returns {Boolean} if the media entry is published
     */
    isPublished(): Boolean {
        return this.media.status === 'draft' || this.media.status === 'waitingForApproval';
    }

    isApproved(): Boolean {
        return this.media.status === 'approved';
    }

    /**
     * retourne true si les icônes d'édition doivent s'afficher pour ce media
     * @returns {Boolean}
     */
    showEditionIcons(): Boolean {
        return this.media.status === 'draft';
    }
    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Ouvre la fenêtre de suppression de média
     */
    openDeleteMediaDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openWarningDialog('DELETE_MEDIA', this.media.id);
    }

    openRenameMediaDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openRenameMediaDialog(this.media);
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Ouvre la fenêtre de publication de média
     */
    publishMedia() {
        this.mediaService.publishMedia(this.media.id);
    }

    cloneMedia($event: any) {
        $event.stopPropagation();
        this.loadingService.startLoading();
        this.cloneMedia$ = this.mediaService.cloneMedia(this.media).subscribe(
            (data: any) => {
                this.loadingService.stopLoading();

                const clonedMedia: Media = {
                    id: data.id,
                    status: 'pending',
                    name: `Copie de ${this.media.name}`,
                    type: this.media.type
                };

                this.selectedMedia = data.id;
                this.mediaService.emitCloneMedia(clonedMedia);
            },
            (error: HttpErrorResponse) => {
                this.loadingService.stopLoading();
            }
        );
    }
}
